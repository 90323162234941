import { Icon } from "office-ui-fabric-react/lib/Icon";
import * as React from "react";

import style from "./style.scss";

export const ErrorText = (props) => {
    return (
      <p className={style.error_text}>
        <Icon iconName={"Info"} />
        {props.errorMsg}
      </p>
    );
  };
