/*
 * Copyright (C) 2017-present Connection Loops Pvt. Ltd., Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import autobind from "autobind-decorator";
import GlobalKeyHandler from "common_assets/GlobalKeyHandler";
import Log from "common_assets/Log";

// import {
//     TooltipHost,
// } from "office-ui-fabric-react/lib/Tooltip";
import React from "react";
// tslint:disable-next-line:no-var-requires
const style = require("./style.scss");
// import APIUrl from "common_assets/URL";
import MenuIcon from "@material-ui/icons/Menu";
import CLStorage, {
  getSchoolInfo,
  ISchoolData,
  IUserStoredObj,
} from "common_assets/CLStorage/CLStorage";
import { IKeyMapping } from "common_assets/GlobalKeyHandler/GlobalKeyHandler";
import { i18nMsg } from "common_assets/i18n/i18n";
import { TooltipHost } from "office-ui-fabric-react/lib/components/Tooltip";
import { Link } from "react-router-dom";
export interface IMenuItem {
  key: string;
  name: string;
  href: string;
  icon: string;
}

export interface INavBarProps {
  pagename?: string;
  bottomShadow?: boolean;
  onMenuToggle?: () => void;
}

export interface INavBarState {
  isUserCallOutVisible: boolean;
  isNotificationCenterOpen: boolean;
}

@autobind
class NavBar extends React.PureComponent<INavBarProps, INavBarState> {
  public static defaultProps: Partial<INavBarProps> = {
    pagename: "<Page Name Here>",
  };

  public storage: CLStorage;
  public logopath: string;
  public labname: any;
  public logger: Log;
  public userIcon: HTMLElement | null;
  public GlobalKeyHandlerMappings: IKeyMapping[];

  private schoolData: ISchoolData | null;

  constructor(props: INavBarProps) {
    super(props);

    this.state = {
      isUserCallOutVisible: false,
      isNotificationCenterOpen: false,
    };
    // KeyBoard Shortcuts

    this.GlobalKeyHandlerMappings = [
      { key: "Alt+u", fun: this.toggleUserCallOut.bind(this) },
    ];
    this.logger = new Log("NavBar");
    this.storage = new CLStorage();

    this.logopath = this.storage.load("logo");
    this.schoolData = getSchoolInfo();
    if (this.logopath) {
      this.logopath = this.logopath.substr(1, this.logopath.length - 2);
      if (this.logopath.lastIndexOf("/") === this.logopath.length - 1) {
        this.logopath += "s_logo.png";
      } else {
        this.logopath += "/s_logo.png";
      }
    }
  }
  public toggleUserCallOut() {
    this.setState({
      isUserCallOutVisible: !this.state.isUserCallOutVisible,
    });
  }

  public onUserCallOutDismiss() {
    this.setState({
      isUserCallOutVisible: false,
    });
  }
  public onNotificationCenterDismiss() {
    this.setState({
      isNotificationCenterOpen: false,
    });
  }
  public toggleNotificationCenter() {
    this.setState({
      isNotificationCenterOpen: !this.state.isNotificationCenterOpen,
    });
  }
  public getUserNameFromLocalStorage(): string {
    const userObj = JSON.parse(this.storage.load("userinfo")) as IUserStoredObj;
    if (userObj && userObj.name != null) {
      return userObj.name;
    }
    // redirect to login
    // window.location.href = APIUrl.LOGIN_URL;
    return "";
  }
  private getGreetings()
  {
    const hrs = (new Date()).getHours();
    if (hrs < 12)
    {
      return "Good Morning, ";
    }
    if (hrs < 16)
    {
      return "Good Afternoon, ";
    }
    return "Good Evening, ";
  }
  public render() {
    // let blinkClass = null;
    // if(isNotificationCenterOpen){
    //     blinkClass = style.blink
    // }
    let userFirstName = "User";
    try {
      userFirstName = this.getUserNameFromLocalStorage();
    } catch (error) {
      // log error
    }

    const schoolName = this.schoolData
      ? this.schoolData.school_name
      : "CL School";
    return (
      <div>
        <GlobalKeyHandler mappings={this.GlobalKeyHandlerMappings} />
        <div
          className={
            style.navbar +
            " " +
            (this.props.bottomShadow ? style.bottomshadow : "")
          }
        >
          <div className={style.LeftGlobalMenuIcon}>
            <TooltipHost content="Toggle Menu" calloutProps={{ gapSpace: 0 }}>
              <MenuIcon
                onClick={this.props.onMenuToggle}
                // iconName="CollapseMenu"
                className={` ms-font-xxl ${style.globalMenuIcon}`}
              />
            </TooltipHost>
          </div>
          <div className={style.labHeader}>
            <Link to="/">
              <img className={style.lablogo} src={this.logopath} />
            </Link>
            <div
              className={`${
                style.labname
              } ms-font-xxl ms-u-hiddenSm ms-fontWeight-semibold`}
            >
              <Link to="/">{schoolName}</Link>
            </div>
          </div>
          {/* <div className={style.pageNameContainer}>
            <span className={style.pageName}>{this.props.pagename}</span>
          </div> */}

          <div className={style.rightMenu}>
            {/* <TooltipHost
              content="Provide feedback"
              calloutProps={{ gapSpace: 0 }}
            >
              <div>
                <Icon
                  iconName="ChatInviteFriend"
                  className={` ms-font-xxl ${style.globalMenuIcon} ${
                    style.rightMenuIcons
                  }`}
                />
              </div>
            </TooltipHost>
            <TooltipHost content="Notifications" calloutProps={{ gapSpace: 0 }}>
              <div>
                <Icon
                  iconName="Ringer"
                  className={` ms-font-xxl ${style.globalMenuIcon} ${
                    style.rightMenuIcons
                  }`}
                />
              </div>
            </TooltipHost> */}
            <p className={style.iconMenuItem + " ms-fontSize-l"}>
              <span>{this.getGreetings()}</span> &nbsp; <span style={{color: "#1565c0", fontWeight: "bold"}}>{userFirstName}</span>
            </p>
            <p className={style.iconMenuItem}>
              <Link
                className={style.iconMenuItemText + " ms-fontSize-l"}
                to="auth/login"
              >
                {i18nMsg.logout}
              </Link>
            </p>
          </div>
        </div>
      </div>
    );

  }
}

export default NavBar;
