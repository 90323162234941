import { preferenceConstants } from "../Constants";
import { ICommonRequestConfig } from "../DataRepository/core/WebApiCaller/APICaller";
import MiscDao from "../DataRepository/dao/MiscDao";
import Logdown from "../LoggerUtil/Logdown";
import { getLogger } from "../LoggerUtil/LoggerUtil";
import { AppURLs } from "../URL";

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    [key: string]: any;
  }
}

export interface ISchoolData {
  board: string;
  board_e: string;
  email: string;
  index_no: string;
  phone_no: string;
  place: string;
  place_e: string;
  sanstha_name: string;
  sanstha_name_e: string;
  school_name: string;
  school_name_e: string;
  u_dise_no: string;
  locale: string;
}

export interface IUserStoredObj {
  iss: string;
  aud: string;
  userUUID: string;
  username: string;
  role: string;
  school_id: string;
  name: string;
  classList: string | string[];
}

let instance: Storage | null = null;
/*
 * This class provied interface for
 * using localstorage and sessionstorage
 * API's
 *
 * @export
 * @class CLStorage
 */

export default class CLStorage {
  public storage: Storage;
  public logger: Logdown;

  private miscDao: MiscDao;

  /**
   * Creates an instance of CLStorage.
   * @memberof CLStorage
   * @param storageType
   */
  constructor(storageType: string = "localStorage") {
    this.logger = getLogger("CLStorage");

    if (this.storageAvailable(storageType)) {
      if (instance === null) {
        instance = window.localStorage;
        if (instance) {
          this.storage = instance;
          this.logger.info("Got localstorage object");
        }
      } else {
        this.storage = instance;
      }
    } else {
      this.logger.error("Local Storage Not Supported");
    }
  }

  /**
   * Store key value properties
   *
   * @param {string} key
   * @param {Object} value
   * @memberof CLStorage
   */
  public write(key: string, value: any) {
    try {
      this.storage.setItem(key, JSON.stringify(value));
    } catch (ex) {
      this.logger.error(`Error occured while setting key=${key}`);
      this.logger.error(ex);
    }
  }

  /**
   * Get stored value with key
   *
   * @param {string} key
   * @memberof CLStorage
   */
  public load(key: string): any {
    try {
      return this.storage.getItem(key);
    } catch (ex) {
      this.logger.error(`Error occured while getting value key=${key}`);
      this.logger.error(ex);
    }
  }

  /**
   * Remove stored item with key
   *
   * @param {string} key
   * @memberof CLStorage
   */
  public remove(key: string) {
    try {
      this.storage.removeItem(key);
    } catch (ex) {
      this.logger.error(`Error occured while removing key=${key}`);
      this.logger.error(ex);
    }
  }

  /**
   * Clear local storage
   *
   * @memberof CLStorage
   */
  public removeAll() {
    try {
      this.storage.clear();
    } catch (ex) {
      this.logger.error(`Error occured while removing all values`);
      this.logger.error(ex);
    }
  }

  public storageAvailable(type: string) {
    try {
      const storage = window[type];
      const x = "__storage_test__";
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === "QuotaExceededError" ||
          // Firefox
          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        // acknowledge QuotaExceededError only if there's something already stored
        this.storage.length !== 0
      );
    }
  }

  public setTableColumnOrderPreference(prefKeyForTable: string, pref: any) {
    let tablePref = this.load(preferenceConstants.TABLE_COLUMN_ORDER_PREF);
    if (tablePref == null) {
      tablePref = {};
      tablePref[prefKeyForTable] = pref;
    } else {
      tablePref = JSON.parse(tablePref);
      try {
        tablePref[prefKeyForTable] = pref;
      }
      catch (e) {
        tablePref = {};
        tablePref[prefKeyForTable] = pref;
      }
    }

    this.write(preferenceConstants.TABLE_COLUMN_ORDER_PREF, tablePref);

    this.savePrefToCloud();
  }

  public getTableColumnOrderPreference(prefKeyForTable: string) {
    const tablePref = this.load(preferenceConstants.TABLE_COLUMN_ORDER_PREF);

    return tablePref != null
      ? JSON.parse(tablePref)[prefKeyForTable]
      : tablePref;
  }

  public setTableColumnHiddenPreference(prefKeyForTable: string, pref: any) {
    let tablePref = this.load(preferenceConstants.TABLE_COLUMN_HIDDEN_PREF);
    if (tablePref == null) {
      tablePref = {};
      tablePref[prefKeyForTable] = pref;
    } else {
      tablePref = JSON.parse(tablePref);
      try {
        tablePref[prefKeyForTable] = pref;
      }
      catch (e) {
        tablePref = {};
        tablePref[prefKeyForTable] = pref;
      }
    }

    this.write(preferenceConstants.TABLE_COLUMN_HIDDEN_PREF, tablePref);
    this.savePrefToCloud();
  }

  public getTableColumnHiddenPreference(prefKeyForTable: string) {
    const tablePref = this.load(preferenceConstants.TABLE_COLUMN_HIDDEN_PREF);

    return tablePref != null
      ? JSON.parse(tablePref)[prefKeyForTable]
      : tablePref;
  }

  public setTableColumnWidthPreference(prefKeyForTable: string, pref: any) {
    let tablePref = this.load(preferenceConstants.TABLE_COLUMN_WIDTH_PREF);
    if (tablePref == null) {
      tablePref = {};
      tablePref[prefKeyForTable] = pref;
    } else {
      tablePref = JSON.parse(tablePref);
      try {
        tablePref[prefKeyForTable] = pref;
      }
      catch (e) {
        tablePref = {};
        tablePref[prefKeyForTable] = pref;
      }
    }

    this.write(preferenceConstants.TABLE_COLUMN_WIDTH_PREF, tablePref);
    this.savePrefToCloud();
  }

  public getTableColumnWidthPreference(prefKeyForTable: string) {
    const tablePref = this.load(preferenceConstants.TABLE_COLUMN_WIDTH_PREF);

    return tablePref != null
      ? JSON.parse(tablePref)[prefKeyForTable]
      : tablePref;
  }

  private savePrefToCloud() {
    this.miscDao = new MiscDao(this.handleSuccess, this.handleFailure);

    const tableWidthPref = this.load(
      preferenceConstants.TABLE_COLUMN_WIDTH_PREF,
    );
    const tableHiddenPref = this.load(
      preferenceConstants.TABLE_COLUMN_HIDDEN_PREF,
    );
    const tableOrderPref = this.load(
      preferenceConstants.TABLE_COLUMN_ORDER_PREF,
    );
    const rcptNoCount = this.load(preferenceConstants.RCPT_NO_PREF);

    const data = {
      table_column_width: tableWidthPref ? JSON.parse(tableWidthPref) : "",
      table_column_hidden: tableWidthPref ? JSON.parse(tableHiddenPref) : "",
      table_column_order: tableWidthPref ? JSON.parse(tableOrderPref) : "",
    };

    if (rcptNoCount) {
      const regex = /\d+/;
      const match = rcptNoCount.match(regex);
      data.pgBrkNoOfRcpt = match.length > 0 ? Number(match[0]) : "0";
    }

    this.logger.info("Saving user preference", data);

    const req: ICommonRequestConfig = {
      method: "PUT",
      url: AppURLs.USER_PREF_API,
      data,
      headers: { moduleId: "misc" },
    };
    this.miscDao.saveUserPreference(req);
  }

  private handleSuccess = (data: any, request: ICommonRequestConfig) => {
    this.logger.info("Success", data);
  }

  private handleFailure = (data: any) => {
    this.logger.error(data);
  }
}

export const getUserObject = (): IUserStoredObj | null => {
  const storage = new CLStorage();
  const userObj = JSON.parse(storage.load("userinfo")) as IUserStoredObj;
  if (userObj) {
    return userObj;
  }
  return null;
};

export const getSchoolInfo = (): ISchoolData | null => {
  const storage = new CLStorage();
  const schoolData = JSON.parse(storage.load("sch")) as ISchoolData;
  if (schoolData) {
    return schoolData;
  }
  return null;
};

export const getSchoolLocale = (): string => {
  const schoolData = getSchoolInfo();
  if (schoolData && schoolData.locale) {
    return schoolData.locale;
  }
  return "mr";
};
