/*
 * Copyright (C) 2017-present Connection Loops Pvt. Ltd., Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/

export class AppURLs {

    public static HELP_LIST_URL = "/api/helplists";
    public static LC_PRINT_URL = "/reports/lc_print.html";
    public static BONAFIED_PRINT_URL = "/reports/bf_print.html";
    public static EMPTY_CATALOG_PRINT_URL = "/reports/catalog/emptycl.html";
    public static CATALOG_PRINT_URL = "/reports/catalog/cl.html";
    public static BASE_URL = location.hostname;
    public static DUMMY_URL = "/dummy";
    public static DUMMY_API_URL = "/api/students";

    // UI URLs
    public static ROOT_PATH = "/";
    public static YEAR_END = "/year-end";
    public static USER_MGMT_URL = "/user-management";
    public static ROLE_MGMT_URL = "/role-management";
    public static CURRENT_URL = "/current";
    public static STUDENT_ADMISSION_URL =  "/new-admission";
    public static STUDENT_MGMT_CURRENT_URL = AppURLs.CURRENT_URL + "-students";
    public static STUDENT_RECEIPT_URL = AppURLs.STUDENT_MGMT_CURRENT_URL + "-fee-receipt";
    public static STUDENT_FEE_MASTER_URL = AppURLs.STUDENT_MGMT_CURRENT_URL + "-fee-master";
    public static STUDENT_CATALOG_URL = AppURLs.STUDENT_MGMT_CURRENT_URL + "-catalog";

    public static MASTERS_URL = "/masters";
    public static TEACHER_MGMT_URL = AppURLs.MASTERS_URL + "-teacher";
    public static SUBJECT_MGMT_URL = AppURLs.MASTERS_URL + "-subject";
    public static STANDARD_DIV_MGMT_URL = AppURLs.MASTERS_URL + "-standard-division";

    public static STUDENT_MGMT_PAST_URL = "/students-past-management";
    public static STUDENT_MGMT_REMOVED_URL = "/catalog-removed-students-management";
    public static DATA_IMPORT_URL = "/data-import";
    public static SETTINGS_URL = "/settings";
    public static SCHOOL_SETTINGS_URL = "/school-settings";
    public static SCHOOL_CLASSES_URL = "/class-settings";
    public static DASHBOARD_URL = "/dashboard";
    public static AUTH_URL = "/auth";
    public static LOGIN_URL = "/auth/login";

    // report related URLs
    public static STUDENT_REPORTS_URL = "/student-reports";
    public static FEE_REPORTS_URL = "/fee-reports";

    // API URLs
    public static LOGIN_API_URL = "/api/login";
    public static USER_API_URL = "/api/users";
    public static REPORTS_API_URL = "/api/reports";
    public static ROLE_API_URL = "/api/roles";
    public static SCHOOL_API_URL = "/api/schools";
    public static GROUP_API_URL = AppURLs.SCHOOL_API_URL + "/group-subjects";
    public static DATA_IMPORT_API_URL = "/api/dataImport";
    public static FILE_UPLOAD_API_URL = "/api/uploadFile";

    public static FILE_UPLOAD_API_NEW_URL = "/api/file/upload";
    public static STUDENT_API_URL = "/api/students";
    public static GET_NEXT_GENERATED_REG_NO = AppURLs.STUDENT_API_URL + "/getNextGenRegNo";
    public static TEACHERS_API_URL = "/api/teachers";
    public static SUBJECT_API_URL = "/api/subjects";
    public static GENERATE_LEAVING_CERT_API_URL = "/api/graduateStudent";
    public static TEACHER_SUBJECT_BINDING_API_URL = "/api/cstb";
    public static FEE_API_URL = "/api/fees";
    public static RECEIPT_API_URL = "/api/recipt";
    public static CATALOG_API_URL = "/api/catalog";
    public static SCHOOL_SETTINGS_API_URL = "/api/schools";
    public static USER_PREF_API = "/api/users/preferences";
}
