// tslint:disable-next-line:no-var-requires
const logdown = require("logdown");

/**
 * Get logger function.
 * For more info check https://github.com/caiogondim/logdown.js, it supports bold italic..
 *
 * Turn on logging for specific component: set debug=Global::componentname in localstorage
 * For multiple componenys seperate with ','.
 *
 * @param prefix log prefix will be used to turn on and off debug logging of perticular component,
 * Keep prefix same as component name
 */
export const getLogger = (prefix: string = "_no_prefix_", prefixColor?: string) => {
 return new logdown("Global::" + prefix, {markdown: true, prefixColor});
};
