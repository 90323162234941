/*
 * Copyright (C) 2017-present Connection Loops Pvt. Ltd., Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/
import * as React from "react";

// load routes from config file
// tslint:disable-next-line:no-var-requires

import { INavLink, INavLinkGroup, Nav } from "office-ui-fabric-react/lib/Nav";
import { AppRoutes } from "./AppRoutes";
// tslint:disable-next-line:no-empty-interface
export interface IMenuPanelProps {}

const MenuPanel = (props: IMenuPanelProps) => {

  // TODO: filter manus based on user role
  const routes: INavLinkGroup [] = AppRoutes.getRoutes();
  return (
    <div>
      <Nav
        onRenderLink={RenderNavLink}
        groups={routes}
      />
    </div>
  );
};

const RenderNavLink = (navLink?: INavLink) => {
  if (navLink!.url === ""){
    return <b>{navLink!.name}</b>;
  } else{
    return <p>{navLink!.name}</p>;
  }
};

export default MenuPanel;
