import {AppURLs} from "common_assets/URL";
import {isCollege} from "common_assets/Util";

export class AppRoutes {

  public static getRoutes = () => {
    const isCol = isCollege();

    return [
      {
        links: [
          // {
          //   name: "Home",
          //   url: "/#" + AppURLs.ROOT_PATH,
          //   key: AppURLs.ROOT_PATH,
          //   // iconProps: { iconName: "AccountManagement" },
          // },
          {
            name: "Current",
            url: "",
            links: [
              {
                name: "New Admission",
                url: "/#" + AppURLs.STUDENT_ADMISSION_URL,
                key: AppURLs.STUDENT_ADMISSION_URL,
                // iconProps: { iconName: "AccountManagement" },
              },
              {
                name: "Students List",
                url: "/#" + AppURLs.STUDENT_MGMT_CURRENT_URL,
                key: AppURLs.STUDENT_MGMT_CURRENT_URL,
                // iconProps: { iconName: "AccountManagement" },
              },
              // {
              //   name: "Catalog",
              //   url: "/#" + AppURLs.STUDENT_CATALOG_URL,
              //   key: AppURLs.STUDENT_CATALOG_URL,
              //   // iconProps: { iconName: "Permissions" },
              // },

            ],
            isExpanded: true,
          },
          {
            name: "Not On Catalog",
            url: "",
            links: [
              {
                name: "Students List",
                url: "/#" + AppURLs.STUDENT_MGMT_REMOVED_URL,
                key: AppURLs.STUDENT_MGMT_REMOVED_URL,
                // iconProps: { iconName: "Permissions" },
              },
            ],
            isExpanded: true,
          },
          {
            name: "Past",
            url: "",
            links: [
              {
                name: "Students List",
                url: "/#" + AppURLs.STUDENT_MGMT_PAST_URL,
                key: AppURLs.STUDENT_MGMT_PAST_URL,
                // iconProps: { iconName: "Permissions" },
              },
            ],
            isExpanded: true,
          },
          // {
          //   name: "Masters",
          //   url: "",
          //   // iconProps: {iconName: "BulkUpload"},
          //   links: [
          //     {
          //       name: "Teachers",
          //       url: "/#" + AppURLs.TEACHER_MGMT_URL,
          //       key: AppURLs.TEACHER_MGMT_URL,
          //       // iconProps: { iconName: "AccountManagement" },
          //     },
          //     {
          //       name: "Subjects",
          //       url: "/#" + AppURLs.SUBJECT_MGMT_URL,
          //       key: AppURLs.SUBJECT_MGMT_URL,
          //       // iconProps: { iconName: "AccountManagement" },
          //     },
          //     {
          //       name: "Classes",
          //       url: "/#" + AppURLs.STANDARD_DIV_MGMT_URL,
          //       key: AppURLs.STANDARD_DIV_MGMT_URL,
          //       // iconProps: { iconName: "AccountManagement" },
          //     },
          //   ],
          //   isExpanded: true,
          // },
          {
            name: "Reports",
            url: "",
            links: [
              {
                name: "Student",
                url: "/#" + AppURLs.STUDENT_REPORTS_URL,
                key: AppURLs.STUDENT_REPORTS_URL,
                // iconProps: { iconName: "Permissions" },
              },
              {
                name: "Financial Reports",
                url: "/#" + AppURLs.FEE_REPORTS_URL,
                key: AppURLs.FEE_REPORTS_URL,
                // iconProps: { iconName: "Permissions" },
              },
            ],
            isExpanded: true,
          },
          {
            name: "Masters",
            url: "",
            links: [
              {
                name: "Fees",
                url: "/#" + AppURLs.STUDENT_FEE_MASTER_URL,
                key: AppURLs.STUDENT_FEE_MASTER_URL,
                // iconProps: { iconName: "Permissions" },
              },
            ],
            isExpanded: false,
          },
          {
            name: "Users",
            url: "",
            links: [
              {
                name: "Users",
                url: "/#" + AppURLs.USER_MGMT_URL,
                key: AppURLs.USER_MGMT_URL,
                // iconProps: { iconName: "AccountManagement" },
              },
              // {
              //   name: "Roles",
              //   url: "/#" + AppURLs.ROLE_MGMT_URL,
              //   key: AppURLs.ROLE_MGMT_URL,
              //   // iconProps: { iconName: "Permissions" },
              // },
            ],
            isExpanded: false,
          },
          {
            name: "Settings",
            url: "",
            // iconProps: {iconName: "BulkUpload"},
            links: [
              {
                name: (isCol ? "College" : "School") + " Settings",
                url: "/#" + AppURLs.SCHOOL_SETTINGS_URL,
                key: AppURLs.SCHOOL_SETTINGS_URL,
                // iconProps: { iconName: "AccountManagement" },
              },
              {
                name: "Class Settings",
                url: "/#" + AppURLs.SCHOOL_CLASSES_URL,
                key: AppURLs.SCHOOL_CLASSES_URL,
                // iconProps: { iconName: "AccountManagement" },
              },
              {
                name: "Excel Data Import",
                url: "/#" + AppURLs.DATA_IMPORT_URL,
                key: AppURLs.DATA_IMPORT_URL,
                // iconProps: { iconName: "AccountManagement" },
              },
            ],
            isExpanded: false,
          },
          // {
          //   name: "Year End Activities",
          //   url: "/#" + AppURLs.YEAR_END,
          //   key: AppURLs.YEAR_END,
          //   // iconProps: { iconName: "AccountManagement" },
          // },
        ],
      },
    ];
  }
}
