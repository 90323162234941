import autobind from "autobind-decorator";
import { toast } from "react-toastify";
import CLRequestClient from "../../../CLRequestClient";
import { getUserObject, IUserStoredObj } from "../../../CLStorage/CLStorage";
import APIError from "../APIError/APIError";
import { IApiCaller, ICommonRequestConfig } from "./APICaller";

/**
 * API caller to make any api call based on provided
 * request configurations
 */
@autobind
class ApiCallerImpl implements IApiCaller {
  private requestClient: CLRequestClient;
  constructor() {
    this.requestClient = new CLRequestClient();
  }

  public async makeAPICall(request: ICommonRequestConfig) {

    // inject school id;
    const modifiedHeaders = {
      ...request.headers,
      school_id: (getUserObject() as IUserStoredObj).school_id,
    };
    request = {
      ...request,
      headers: modifiedHeaders,
    };

    try {
      const response = await this.requestClient
        .request(request);
      // check if data has result property
      if (request.successCallback) {
        request.successCallback.call(this, response.data, request);
      }
    }
    catch (error) {
      // todo: construct errorObject
      const errorObject: APIError = {
        message: "Unknown Error occured !",
        code: 999,
        name: "",
      };
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // this is response from our server , parse response
        if (error.response.data &&
          error.response.data.code &&
          error.response.data.msg) {
          const data = error.response.data;
          errorObject.code = data.code;
          errorObject.message = data.msg;
        }
        else {
          errorObject.code = error.request.status;
          errorObject.message = error.request.statusText; // TODO: need to confirm this
        }
      }
      else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        errorObject.code = error.request.status;
        errorObject.message = error.request.statusText; // TODO: need to confirm this
      }
      else {
        // Something happened in setting up the request that triggered an Error
        errorObject.message = error.message;
        console.error("Error", error.message);
      }
      if (request.failureCallback) {
        request.failureCallback.call(this, errorObject, (request.url!));
      }
      else {
        toast.error(errorObject.message + ", error code: " + errorObject.code);
      }
    }
  }
}

export default ApiCallerImpl;
