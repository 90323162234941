import autobind from "autobind-decorator";

import Logdown from "../../LoggerUtil/Logdown";
import { getLogger } from "../../LoggerUtil/LoggerUtil";
import BaseDaoImpl from "../core/Dao/BaseDaoImpl";
import { ICommonRequestConfig } from "../core/WebApiCaller/APICaller";

@autobind
class MiscDao extends BaseDaoImpl {
  private parentSuccessCallback: (data: any, request: ICommonRequestConfig) => void; // VSC
  private parentFailureCallback: (data: any) => void; // VFC
  private log: Logdown;
  private commonConfig: ICommonRequestConfig;

  constructor(
    successCallback: (data: any, request: ICommonRequestConfig) => void,
    failureCallback: (data: any) => void,
  ) {
    super();
    this.parentSuccessCallback = successCallback;
    this.parentFailureCallback = failureCallback;

    this.log = getLogger("MiscDao");
    // common configuration for all api request
    this.commonConfig = {
      failureCallback,
      successCallback,
    };
  }

  public fileUpload(request: ICommonRequestConfig) {
    request = {
      ...request,
      ...this.commonConfig,
    };
    return this.doRequest(request);
  }

  public saveUserPreference(request: ICommonRequestConfig){
    request = {
      ...request,
      ...this.commonConfig,
    };
    return this.doRequest(request);
  }
}

export default MiscDao;
