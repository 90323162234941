import autobind from "autobind-decorator";
import CLRequestClient from "common_assets/CLRequestClient";
import CLStorage, {getSchoolLocale} from "common_assets/CLStorage/CLStorage";
import {AppURLs} from "common_assets/URL";
import {ErrorText} from "components/BasicComponents/PureComponents";
import {IBaseProps} from "components/CommonInterfaces/CommonInterfaces";
import enableI18n from "decorators/EnableI18n";
import {DefaultButton} from "office-ui-fabric-react/lib/Button";
import {TextField} from "office-ui-fabric-react/lib/components/TextField";
import {Icon} from "office-ui-fabric-react/lib/Icon";
import {Spinner, SpinnerSize} from "office-ui-fabric-react/lib/Spinner";
import * as React from "react";
import {i18nMsg} from "../../common_assets/i18n/i18n";
// import { Link } from "react-router-dom";
import style from "./style.scss";

export interface ILoginFormProps extends IBaseProps {
  dummyProps: string;
}

export interface ILoginFormState {
  inProgress: boolean;
  hasError: boolean;
}

export interface ILoginRequest {
  username: string;
  password: string;
}

@autobind
export class LoginForm extends React.PureComponent<ILoginFormProps,
  ILoginFormState> {
  private storage: CLStorage;
  private requestObject: ILoginRequest;
  private requestClient: CLRequestClient;
  private error: string;

  constructor(props) {
    super(props);

    this.requestClient = new CLRequestClient();
    this.storage = new CLStorage();

    this.state = {
      hasError: false,
      inProgress: false,
    };

    this.requestObject = {
      password: "",
      username: "",
    };
    this.storage.removeAll();
  }

  private doValidations(): boolean {
    return this.requestObject.password !== "" && this.requestObject.username !== "";
  }

  public successCallback(studentData: any) { // instead of any it can be specific to type of data
    console.log(studentData);
  }

  public failureCallback(error) {
    console.log(error);
  }

  private handleLogin(): void {

    if (!this.doValidations()) {
      this.error = "Enter login credentials";
      this.setState({hasError: true, inProgress: false});
      return;
    }

    this.setState({inProgress: true});
    this.requestClient
      .post(AppURLs.LOGIN_API_URL, this.requestObject, {})
      .then((response) => {
        this.storeUserInfo(response.data);
        this.props.history.replace("/");

        // this should fix locale not changing issue
        const locale = getSchoolLocale();
        i18nMsg.setLanguage(locale);

        window.school_locale = locale;
      })
      .catch((err) => {
        this.error = err.response.data.msg;
        this.setState({hasError: true, inProgress: false});
      });
  }

  public storeUserInfo(data: any) {
    if (data.userProperties) {
      this.storage.write("userinfo", data.userProperties);
    }
    if (data.schoolInfo) {
      this.storage.write("sch", data.schoolInfo);
    }
    if (data.blob_url) {
      this.storage.write("logo", data.blob_url);
    }

    if (data.preferences) {
      for (const key of Object.keys(data.preferences)) {

        if (key === "pgBrkNoOfRcpt") {
          const regex = /\d+/;
          let rcptNoCount = 0;
          if (typeof data.preferences[key] === "number") {
            rcptNoCount = data.preferences[key];
          } else {
            const match = data.preferences[key].match(regex)[0];
            rcptNoCount = match.length > 0 ? match[0] : "0";
          }

          this.storage.write(key, Number(rcptNoCount));
        } else {
          this.storage.write(key, data.preferences[key]);
        }
      }
    }
  }

  private handleInputChange(field: string, value: string): void {
    // close error message box
    if (this.state.hasError) {
      this.setState({hasError: false});
    }

    switch (field) {
      case "phone":
        this.requestObject = {
          ...this.requestObject,
          username: value,
        };
        break;
      case "password":
        this.requestObject = {
          ...this.requestObject,
          password: value,
        };
        break;
    }
  }

  public render(): JSX.Element {
    return (
      <div className={" ms-slideLeftIn10"}>
        <p className={style.login_form_heading}>{this.props.i18nMsg.login}</p>
        {this.state.hasError && (
          <ErrorText {...this.props} errorMsg={this.error}/>
        )}

        <div className={style.login_form_input_container}>
          <form>
            <TextField
              className={style.auth_input_field}
              inputClassName={style.auth_input}
              placeholder={this.props.i18nMsg.enter_username}
              type="text"
              underlined={true}
              autoComplete="on"
              autoFocus={true}
              onRenderPrefix={renderSuffix}
              onChanged={this.handleInputChange.bind(this, "phone")}
            />
            <TextField
              type="password"
              autoComplete="on"
              underlined={true}
              className={style.auth_input_field}
              inputClassName={style.auth_input}
              placeholder={this.props.i18nMsg.enter_password}
              onRenderPrefix={renderSuffix}
              onChanged={this.handleInputChange.bind(this, "password")}
            />

            <DefaultButton
              className={style.auth_button}
              onClick={this.handleLogin}
              type="submit"
              disabled={this.state.inProgress}
            >
              {this.state.inProgress ? (
                <Spinner size={SpinnerSize.small}/>
              ) : (
                this.props.i18nMsg.login
              )}
            </DefaultButton>
          </form>
          {/* <LoginFooter {...this.props} /> */}
        </div>
      </div>
    );
  }
}

// const LoginFooter = (props) => {
//   return (
//     <div className={style.login_form_footer}>
//       <p>
//         {props.i18nMsg.dont_have_account}{" "}
//         <Link to="auth/signup">{props.i18nMsg.signup_here}</Link>
//       </p>
//       <p>Or</p>
//       <p>
//         <Link to="auth/forget">{props.i18nMsg.forget_password}</Link>
//       </p>
//     </div>
//   );
// };

const renderSuffix = (props) => {
  let iconClassName = "";
  iconClassName = props.type === "password" ? "Lock" : "Contact";
  return <Icon iconName={iconClassName} className={"ms-font-xl"}/>;
};
export default enableI18n(LoginForm);
