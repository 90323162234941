import {ISchoolClass} from "common_assets/DataRepository/dao/SchoolDao";
import {IExpandedClass} from "components/CommonInterfaces/CommonInterfaces";
import {toast} from "react-toastify";
import SchoolInfo from "../../views/Settings/ScholInfo";

/*
 * Copyright (C) 2017-present Connection Loops Pvt. Ltd., Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

/*eslint-disable  no-useless-escape*/

/**
 * This function modifies default font size based on labsswitches
 *
 * if div or span does not have font size it will not touch
 * @param html
 * @param multi
 * @param defFS
 */
export const processHTML2 = (
  html: string,
  multi: number,
  defFS: number = 10,
): string => {
  const unit: string = "px";
  const ptunit: string = "pt";
  // const plusNumber: number = 16;
  // const MinusNumber: number = 11;
  // const changeTo = multi > 0 ? plusNumber : MinusNumber;
  const defSize: number = defFS !== undefined ? defFS : 10;
  const plusOffset: number = 12 - defSize;
  const minusOffset: number = defSize - 12;
  const offset = multi > 0 ? plusOffset : minusOffset;
  const domParser = new DOMParser();
  const doc = domParser.parseFromString(html, "text/html").documentElement;
  if (doc !== null) {
    const spans = doc.getElementsByTagName("span");
    const divs = doc.getElementsByTagName("div");
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < spans.length; i++) {
      let originalFS = defSize;
      if (spans[i].style.fontSize !== null && spans[i].style.fontSize !== "") {
        originalFS = parseInt(
          spans[i].style.fontSize!.replace(unit, "").replace(ptunit, ""),
          10,
        );
        if (isNaN(originalFS)) {
          originalFS = defSize;
        }
      }
      spans[i].style.fontSize = originalFS + offset + ptunit;
    }

    for (let i = 0; i < divs.length; i++) {
      let originalFS = defSize;
      if (divs[i].style.fontSize !== null && divs[i].style.fontSize !== "") {
        originalFS = parseInt(
          divs[i].style.fontSize!.replace(unit, "").replace(ptunit, ""),
          10,
        );
        if (isNaN(originalFS)) {
          originalFS = defSize;
        }
      }
      divs[i].style.fontSize = originalFS + offset + ptunit;
    }

    return doc.getElementsByTagName("body")[0].innerHTML;
  }
  return html;
};

export const getUrlParametersByName = (
  name: string,
  url?: string,
): string | null => {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export function pad2(num: number) {
  return (num < 10 ? "0" : "") + num;
}

export const getDateInIndianFormat = (dtIn: string): string => {
  if (dtIn != null) {
    return (
      getOnlyDateInIndianFormat(dtIn) + " " + getOnlyTimeInIndianFormat(dtIn)
    );
  } else {
    return "";
  }
};
export const getOnlyDateInIndianFormat = (dtIn: string): string => {
  if (dtIn != null) {
    const dtObj = new Date(dtIn);
    return (
      pad2(dtObj.getDate()) +
      "/" +
      pad2(dtObj.getMonth() + 1) +
      "/" +
      dtObj.getFullYear()
    );
  } else {
    return "";
  }
};
export const getOnlyTimeInIndianFormat = (dtIn: string): string => {
  if (dtIn != null) {
    const dtObj = new Date(dtIn);
    return (
      pad2(dtObj.getHours()) +
      ":" +
      pad2(dtObj.getMinutes()) +
      ":" +
      pad2(dtObj.getSeconds())
    );
  } else {
    return "";
  }
};

// used by date pickers to convert date to string to display in text box
export const formatDate = (dt: Date) => {
  if (dt.getFullYear() === 1900) {
    // epoch date means no date input
    return "";
  }
  // this doesn't consider time
  return getDateInIndianFormat(dt.toISOString()).split(" ")[0];
};

// used by date pickers to convert string to date to select in expanded panel and to value
export const parseDateFromString = (str: string): Date => {
  try {
    if (str === "") {
      throw -1;
    }
    // this discards time
    const parts = str.split(" ");
    const dtPart = parts[0].split("/");
    if (parts.length === 1) {
      const dt = new Date(
        parseInt(dtPart[2], 10),
        parseInt(dtPart[1], 10) - 1,
        parseInt(dtPart[0], 10),
      );
      return dt;
    } else {
      const timePart = parts[1].split(":");
      const dt = new Date(
        parseInt(dtPart[2], 10),
        parseInt(dtPart[1], 10) - 1,
        parseInt(dtPart[0], 10),
        parseInt(timePart[0], 10),
        parseInt(timePart[1], 10),
        parseInt(timePart[2], 10),
      );
      return dt;
    }
  } catch (ex) {
    console.log(
      "Failed to parse given date. str is " + str + "returning default string ",
    );
    return new Date(1900, 0, 1); // empty date for us
  }
};
export const updateQueryStringParameter = (
  key: string,
  value: any,
  url?: string,
) => {
  if (!url) {
    url = window.location.href;
  }
  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = url.indexOf("?") !== -1 ? "&" : "?";

  url = url.match(re)
    ? url.replace(re, "$1" + key + "=" + value + "$2")
    : (url = url + separator + key + "=" + value);

  // Update only query string , does not reload the page
  window.history.pushState({}, "", url);
};

export const getValidationResult = (validationObject: any) => {
  let op = true;
  for (const bit in validationObject) {
    if (validationObject.hasOwnProperty(bit)) {
      op = op && validationObject[bit];
    }
  }
  if (!op) {
    // tslint:disable-next-line:no-console TODO:remove console line
    console.log("Validation Failed : " + JSON.stringify(validationObject));
  }
  return op;
};

export const DictionaryToArray = (
  obj: any,
  keyField?: string,
  valueField?: string,
) => {
  const _kf = keyField !== undefined ? keyField : "key";
  const retVal: any[] = [];
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      const iter_obj = obj[prop];
      if (iter_obj !== null && typeof iter_obj === "object") {
        iter_obj[_kf] = prop;
        retVal.push(iter_obj);
      } else {
        const newObject: any = {};
        newObject[_kf] = prop;
        newObject[valueField !== undefined ? valueField : "value"] = iter_obj;
        retVal.push(newObject);
      }
    }
  }
  return retVal;
};

export const ArrayToDictionary = (arr: any[], keyField: string) => {
  let i = arr.length;
  const retVal: any = {};
  while (i--) {
    if (arr[i] && typeof arr[i] === "object") {
      retVal[arr[i][keyField]] = arr[i];
    }
  }
  return retVal;
};

export const setValidationBit = (
  validationobject?: any,
  bit?: string | undefined,
  appendValue?: boolean | undefined,
) => {
  if (appendValue !== undefined) {
    validationobject[bit!] = appendValue;
  }
};

export const setModuleID = (modueID: string) => {
  // tslint:disable-next-line:no-console TODO:remove console line
  console.log("Setting moduleid: " + modueID);
  window.moduleID = modueID;
};

export const copyObj = (src: any) => {
  const dest: any = {};
  for (const prop in src) {
    if (src.hasOwnProperty(prop)) {
      dest[prop] = typeof prop === "object" ? copyObj(src[prop]) : src[prop];
    }
  }
  return dest;
};

export const copyObjRec = (src: any) => {
  const dest: any = {};
  for (const prop in src) {
    if (src.hasOwnProperty(prop)) {
      dest[prop] =
        typeof src[prop] === "object" ? copyObj(src[prop]) : src[prop];
    }
  }
  return dest;
};
export const getObjFromArray = (
  src: any,
  attributeName: string,
  val: string | number,
) => {
  let i = src.length;
  while (i--) {
    if (
      src[i] &&
      src[i].hasOwnProperty(attributeName) &&
      src[i][attributeName] === val
    ) {
      return src[i];
    }
  }
};

export const deleteObj = (
  src: any,
  attributeName: string,
  val: string | number,
) => {
  let i = src.length;
  while (i--) {
    if (
      src[i] &&
      src[i].hasOwnProperty(attributeName) &&
      src[i][attributeName] === val
    ) {
      src.splice(i, 1);
    }
  }
};

export const getCharValueFromKeyCode = (keycode: number): string | null => {
  switch (keycode) {
    case 48:
    case 96:
      return "0";
    case 49:
    case 97:
      return "1";
    case 50:
    case 98:
      return "2";
    case 51:
    case 99:
      return "3";
    case 52:
    case 100:
      return "4";
    case 53:
    case 101:
      return "5";
    case 54:
    case 102:
      return "6";
    case 55:
    case 103:
      return "7";
    case 56:
    case 104:
      return "8";
    case 57:
    case 105:
      return "9";
    default:
      return null;
  }
};

export const getRoundedVal = (val: any, decimalPlaces: any): string => {
  if (!isNaN(val)) {
    val = parseFloat(val).toFixed(decimalPlaces);
  }
  return val;
};

export const showErrorToast = (msg, error) => {
  toast.error(
    `${msg}, \n message: ${error.message} , error code: ${error.code}`,
    {delay: 2500},
  );
};

export const getLocale = () => {
  const jo = JSON.parse(window.localStorage.sch);
  if (jo.hasOwnProperty("locale")) {
    return jo.locale;
  } else {
    return "mr";
  }
};
/**
 *
 * @param a Number Sorting function to be used for columns sort
 * @param b
 */
export const numberSort = (a, b) => {
  const num1 = parseInt(a, 10);
  const num2 = parseInt(b, 10);
  if (num1 === num2) {
    return 0;
  }
  return (num1 < num2) ? -1 : 1;
};

/**
 *
 * @param a String Sorting function to be used for columns sort
 * @param b
 */
export const stringSort = (a: string, b: string) => {
  return (a.toLocaleLowerCase() < b.toLocaleLowerCase()) ? -1 : 1;
};

function getClassLabel(std, locale) {
  switch (std) {
    case "n3":
      return "-";
    case "n2":
      return "Mini KG";
    case "n1":
      return locale === "en" ? "Jr. KG" : "लहान गट";
    case "n0":
      return locale === "en" ? "Sr. KG" : "मोठा गट";
    case "13":
      return "BA-I";
    case "14":
      return "BA-II";
    case "15":
      return "BA-III";

    case "16":
      return "BSc.I";
    case "17":
      return "BSc.II";
    case "18":
      return "BSc.III";

    case "all" :
      return "All";
    default:
      return (locale === "mr" ? etom(std) : std);

  }
}

export const getStdAbsVal = (std: string): number => {
  if (std === "n3") {
    return -3;
  }
  if (std === "n2") {
    return -2;
  }
  if (std === "n1") {
    return -1;
  }
  if (std === "n0") {
    return 0;
  }
  return parseInt(std);
};

// 1_def_a  | en , mr
export const expandClassCode = (cc: string, locale: string): IExpandedClass => {
  // 5_def_a
  const parts = cc.split("_");
  const std_raw = parts[0];
  const std = getClassLabel(std_raw, locale);
  const std_suffix = getStdSuffix(std_raw, locale);
  const branch_code = parts[1];
  const branch = SchoolInfo.getBranchMap(locale)[branch_code];
  const div_raw = parts[2];
  const div = SchoolInfo.getDivisionMap(locale)[div_raw];

  const retval: IExpandedClass = {
    std,
    std_code: std_raw,
    std_suffix,
    branch,
    branch_code,
    div,
    div_code: div_raw,
  };
  return retval;

};

const getStdSuffix = (std, locale) => {
  if (locale === "mr") {
    switch (getStdAbsVal(std)) {
      case -1 :
        return "";
      case -2 :
        return "";
      case -3 :
        return "";
      case -4 :
        return "";
      case 1:
        return "ली";
      case 2:
        return "री";
      case 3:
        return "री";
      case 4:
        return "थी";
      default:
        if (std > 12) {
          return "";
        }
        return "वी";
    }
  } else {
    switch (getStdAbsVal(std)) {
      case -1 :
        return "";
      case -2 :
        return "";
      case -3 :
        return "";
      case -4 :
        return "";
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        if (std > 12) {
          return "";
        } else {
          return "th";
        }
    }

  }

};

export const mtoe = (s) =>
{
    s = s.toString();
    let sout = "";
    for (let i = 0; i < s.length; i++)
    {
        switch (s[i])
        {
                case "१": sout += "1"; break;
                case "०": sout += "0"; break;
                case "२": sout += "2"; break;
                case "३": sout += "3"; break;
                case "४": sout += "4"; break;
                case "५": sout += "5"; break;
                case "६": sout += "6"; break;
                case "७": sout += "7"; break;
                case "८": sout += "8"; break;
                case "९": sout += "9"; break;
                case ".": sout += "."; break;
                default: sout += s[i]; break;

        }
    }
    return (sout);

};

export const etom = (s: string) => {
  s = s.toString();
  let sout = "";
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < s.length; i++) {
    switch (s[i]) {
      case "1":
        sout += "१";
        break;
      case "0":
        sout += "०";
        break;
      case "2":
        sout += "२";
        break;
      case "3":
        sout += "३";
        break;
      case "4":
        sout += "४";
        break;
      case "5":
        sout += "५";
        break;
      case "6":
        sout += "६";
        break;
      case "7":
        sout += "७";
        break;
      case "8":
        sout += "८";
        break;
      case "9":
        sout += "९";
        break;
      case ".":
        sout += ".";
        break;
      default:
        sout += s[i];
        break;

    }
  }
  return sout;
};

export const getLocaleNumeralsUI = (s?: string) =>
{
  const locale = getLocale();
  if (locale === "mr")
  {
    // etom
    if (s) {
      return etom(s);
    }
    else {
      return s;
    }

  }
  else
  {
    return s;
  }

};
export const getLocaleNumeralsBack = (s?: string) =>
{
  const locale = getLocale();
  if (locale === "mr")
  {
    // etom
    if (s) {
      return mtoe(s);
    }
    else {
      return s;
    }
  }
  else
  {
    return s;
  }

};
export const getInClassCodeFormat = (std, branch, div) => {
  return `${std}_${branch}_${div}`;
};

export const getFilesizeInReadableFormat = (bytes: number) => {
  const thresh = 1000;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + " " + units[u];
};

export const getAcademicYear = () => {
  const dt = new Date();
  const month = dt.getMonth() + 1;
  const year = dt.getFullYear();
  if (month < 6) {
    return (year - 1);
  } else {
    return year;
  }
};

export const isString = (s) => {
  return typeof (s) === "string" || s instanceof String;
};

export const getMaxClass = (list: ISchoolClass[]) => {
  let res = -100;
  for (let i = 0; i < list.length; i++) {
    const cc = parseInt(list[i].class_code.split("_")[0], 10);
    if (cc > res) {
      res = cc;
    }
  }
  return res;
};

/* ****************************

Date string functions

* ********************************/
const marathiRawNums = [
  "",
  "एक",
  "दोन",
  "तीन",
  "चार",
  "पाच",
  "सहा",
  "सात",
  "आठ",
  "नऊ",
  "दहा",
  "अकरा",
  "बारा",
  "तेरा",
  "चौदा",
  "पंधरा",
  "सोळा",
  "सतरा",
  "अठरा",
  "एकोणीस",
  "वीस",
  "एकवीस",
  "बावीस",
  "तेवीस",
  "चोवीस",
  "पंचवीस",
  "सव्वीस",
  "सत्तावीस",
  "अठ्ठावीस",
  "एकोणतीस",
  "तीस",
  "एकतीस",
  "बत्तीस",
  "तेहेतीस",
  "चौतीस",
  "पस्तीस",
  "छत्तीस",
  "सदतीस",
  "अडतीस",
  "एकोणचाळीस",
  "चाळीस",
  "एक्केचाळीस",
  "बेचाळीस",
  "त्रेचाळीस",
  "चव्वेचाळीस",
  "पंचेचाळीस",
  "सेहेचाळीस",
  "सत्तेचाळीस",
  "अठ्ठेचाळीस",
  "एकोणपन्नास",
  "पन्नास",
  "एक्कावन्न",
  "बावन्न",
  "त्रेपन्न",
  "चोपन्न",
  "पंचावन्न",
  "छप्पन्न",
  "सत्तावन्न",
  "अठ्ठावन्न",
  "एकोणसाठ",
  "साठ",
  "एकसष्ठ",
  "बासष्ठ",
  "त्रेसष्ठ",
  "चौसष्ठ",
  "पासष्ठ",
  "सहासष्ठ",
  "सदुसष्ठ",
  "अडुसष्ठ",
  "एकोणसत्तर",
  "सत्तर",
  "एक्काहत्तर",
  "बाहत्तर",
  "त्र्याहत्तर",
  "चौर्‍याहत्तर",
  "पंच्याहत्तर",
  "शहात्तर",
  "सत्याहत्तर",
  "अठ्ठ्याहत्तर",
  "एकोण ऐंशी",
  "ऐंशी",
  "एक्क्याऐंशी",
  "ब्याऐंशी",
  "त्र्याऐंशी",
  "चौऱ्याऐंशी",
  "पंच्याऐंशी",
  "शहाऐंशी",
  "सत्त्याऐंशी",
  "अठ्ठ्याऐंशी",
  "एकोणनव्वद",
  "नव्वद",
  "एक्क्याण्णव",
  "ब्याण्णव",
  "त्र्याण्णव",
  "चौऱ्याण्णव",
  "पंच्याण्णव",
  "शहाण्णव",
  "सत्त्याण्णव",
  "अठ्ठ्याण्णव",
  "नव्व्याण्णव",
];
const englishRawDTNums = [
  "",
  "First",
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Ninth",
  "Tenth",
  "Eleventh",
  "Twelfth",
  "Thirteenth",
  "Fourteenth",
  "Fifteenth",
  "Sixteenth",
  "Seventeenth",
  "Eighteenth",
  "Nineteenth",
  "Twentieth",
  "Twenty First",
  "Twenty Second",
  "Twenty Third",
  "Twenty Fourth",
  "Twenty Fifth",
  "Twenty Sixth",
  "Twenty Seventh",
  "Twenty Eighth",
  "Twenty Ninth",
  "Thirtieth",
  "Thirty First",
];

const englishRawNums = [
  "",
  "One",
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
  "Ten",
  "Eleven",
  "Twelve",
  "Thirteen",
  "Fourteen",
  "Fifteen",
  "Sixteen",
  "Seventeen",
  "Eighteen",
  "Nineteen",
  "Twenty",
  "Twenty One",
  "Twenty Two",
  "Twenty Three",
  "Twenty Four",
  "Twenty Five",
  "Twenty Six",
  "Twenty Seven",
  "Twenty Eight",
  "Twenty Nine",
  "Thirty",
  "Thirty One",
  "Thirty Two",
  "Thirty Three",
  "Thirty Four",
  "Thirty Five",
  "Thirty Six",
  "Thirty Seven",
  "Thirty Eight",
  "Thirty Nine",
  "Forty",
  "Forty One",
  "Forty Two",
  "Forty Three",
  "Forty Four",
  "Forty Five",
  "Forty Six",
  "Forty Seven",
  "Forty Eight",
  "Forty Nine",
  "Fifty",
  "Fifty One",
  "Fifty Two",
  "Fifty Three",
  "Fifty Four",
  "Fifty Five",
  "Fifty Six",
  "Fifty Seven",
  "Fifty Eight",
  "Fifty Nine",
  "Sixty",
  "Sixty One",
  "Sixty Two",
  "Sixty Three",
  "Sixty Four",
  "Sixty Five",
  "Sixty Six",
  "Sixty Seven",
  "Sixty Eight",
  "Sixty Nine",
  "Seventy",
  "Seventy One",
  "Seventy Two",
  "Seventy Three",
  "Seventy Four",
  "Seventy Five",
  "Seventy Six",
  "Seventy Seven",
  "Seventy Eight",
  "Seventy Nine",
  "Eighty",
  "Eighty One",
  "Eighty Two",
  "Eighty Three",
  "Eighty Four",
  "Eighty Five",
  "Eighty Six",
  "Eighty Seven",
  "Eighty Eight",
  "Eighty Nine",
  "Ninety",
  "Ninety One",
  "Ninety Two",
  "Ninety Three",
  "Ninety Four",
  "Ninety Five",
  "Ninety Six",
  "Ninety Seven",
  "Ninety Eight",
  "Ninety Nine",
];
const mr_months = [
  "जानेवारी",
  "फेब्रुवारी",
  "मार्च",
  "एप्रिल",
  "मे",
  "जून",
  "जुलै",
  "ऑगस्ट",
  "सप्टेंबर",
  "ऑक्टोबर",
  "नोव्हेंबर",
  "डिसेंबर",
];
const en_months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMarathiStringForDate = (dt: string, noDay?: boolean) => {
  let retVal = "";
  const parts = dt.split("/");
  try
  {

    const fyp = parseInt(dt.split("/")[2].substr(0));
    const yp = parseInt(dt.split("/")[2].substr(2), 10);
    const dp = parseInt(parts[0], 10);
    const mp = parseInt(parts[1], 10);
    const yearPrefix = fyp < 2000 ? "एकोणिसशे " : "दोन हजार ";

    if (noDay) {
      return mr_months[mp - 1] + " " + yearPrefix + marathiRawNums[yp];
    }

    if (!isNaN(dp)) {
      retVal += marathiRawNums[dp];
    }
    if (!isNaN(mp)) {
      retVal += " " + mr_months[mp - 1];
    }
    if (!isNaN(yp)) {
      retVal += " " + yearPrefix + marathiRawNums[yp];
    }
  }
  catch (e){}
  return retVal;

};

export const getEnglishStringForDate = (dt: string, noDay?: boolean) => {
  let retVal = "";
  const fyp = parseInt(dt.split("/")[2].substr(0));
  const yp = parseInt(dt.split("/")[2].substr(2), 10);
  const dp = parseInt(dt.split("/")[0], 10);
  const mp = parseInt(dt.split("/")[1], 10);
  const yearPrefix = fyp < 2000 ? "Nineteen Hundred " : "Two Thousand ";

  if (noDay) {
    return en_months[mp - 1] + " " + yearPrefix + englishRawNums[yp];
  }

  if (!isNaN(dp)) {
    retVal += englishRawDTNums[dp];
  }
  if (!isNaN(mp)) {
    retVal += " " + en_months[mp - 1];
  }
  if (!isNaN(yp)) {
    retVal += " " + yearPrefix + englishRawNums[yp];
  }
  return retVal;
};

export const yearInStr = (year: string, locale: string) => {
  let yearPrefix = parseInt(year, 10) < 2000 ? "Nineteen Hundred " : "Two Thousand ";
  if (locale == "mr")
  {
    yearPrefix = parseInt(year, 10) < 2000 ? "एकोणिसशे " : "दोन हजार ";
  }
  if (locale == "en") {
    return yearPrefix + englishRawNums[(parseInt(year, 10) - 2000)];
  }
  if (locale == "mr") {
    return yearPrefix + marathiRawNums[(parseInt(year, 10) - 2000)];
  }
};

export const getStringForDt = (dt: string, locale, noDay?: boolean) => {
  return locale === "mr" ? getMarathiStringForDate(dt) : getEnglishStringForDate(dt);
};

export const isCollege = (): boolean => {
  const tc = JSON.parse(window.localStorage.sch).isTC;
  return tc === "1";
};

export const bfCopy = (): boolean => {
  const tc = JSON.parse(window.localStorage.sch).bfCopy;
  return tc === "1";
};
