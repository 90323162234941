import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { initializeIcons } from "@uifabric/icons";
import App from "App";
import CLStorage from "common_assets/CLStorage";
import { AppURLs } from "common_assets/URL";
import { Fabric } from "office-ui-fabric-react/lib/Fabric";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route } from "react-router-dom";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2196F3",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
  spacing: {
    unit: 6,
  },
  overrides: {
    MuiCheckbox: {
      root: {
        padding: 6,
      },
    },
    MuiListItem : {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      "CLFont",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

if ((module as any).hot) {
  (module as any).hot.accept();
}

initializeIcons();

const MainApp = () => {
  return (
    <Router>
      <Fabric>
        <MuiThemeProvider theme={theme}>
          <Route path={AppURLs.ROOT_PATH} component={App} />
        </MuiThemeProvider>
      </Fabric>
    </Router>
  );
};

ReactDOM.render(<MainApp />, document.getElementById("rcontainer"));
