/*
 * Copyright (C) 2017-present Connection Loops Pvt. Ltd., Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/
/* eslint-disable no-console*/
import { logLevel } from "../Constants";
/*
* Author : Ashutosh Mangalekar
* Date : 2017-06-04 22:54:41
* Description:
* Logger for frontend
* to change log level set appropriate keys to localstorage
* for more info refer common_assets/Constants
* eg. logLevel : 3
*/
class Log {
  public logLevel: number;
  constructor(public who: string) {
    this.who = who;
    const level: string = (localStorage.getItem("logLevel") !== undefined ? localStorage.getItem("logLevel") : "0")!;
    this.logLevel = parseInt(level, 10);
  }
  /*
     * Log error message
     * @param {*String} msg
     */
  public error(msg: string) {
    if (this.logLevel >= logLevel.ERROR) {
      // tslint:disable-next-line:no-console
      console.error(`${"%c ["}${this.getLogLevel()}]::${new Date().toLocaleTimeString()} [${this.who}]\nError::${msg}`,
       "background:#d50000;color:#fff"); }
  }

  /*
     * Log info message
     * @param {*String} msg
     */
  public info(msg: string) {
    if (this.logLevel >= logLevel.INFO) {
      // tslint:disable-next-line:no-console
      console.info(`[${this.getLogLevel()}]::${new Date().toLocaleTimeString()}`, ` [${this.who}]\n${msg}`);
    }
  }

  /*
     * Log Debug message
     * @param {*String} msg
     */
  public debug(msg: string) {
    if (this.logLevel >= logLevel.DEBUG) {
      // tslint:disable-next-line:no-console
      console.log(`[${this.getLogLevel()}]::${new Date().toLocaleTimeString()}`, ` [${this.who}]\n${msg}`);
    }
  }

  /*
     * Start the timer
     * @param {*String} id
     */
  public startTime(id: string) {
    // tslint:disable-next-line:no-console
    if (this.logLevel >= logLevel.DEBUG) { console.time(id); }
  }

  /*
     * End the timer
     * @param {*String} id
     */
  public endTime(id: string) {
    // tslint:disable-next-line:no-console
    if (this.logLevel >= logLevel.DEBUG) { console.timeEnd(id); }
  }

  public getLogLevel() {
    switch (this.logLevel) {
      case 0:
        return "ERROR";
      case 1:
        return "INFO";
      case 2:
        return "DEBUG";
      default:
        return "ERROR";
    }
  }
}

export default Log;
