import autobind from "autobind-decorator";
import { getSchoolLocale } from "common_assets/CLStorage/CLStorage";
import { i18nMsg } from "common_assets/i18n/i18n";
import { getLogger } from "common_assets/LoggerUtil/LoggerUtil";
import { AppURLs } from "common_assets/URL";
import enableI18n from "decorators/EnableI18n";
import Main from "Main";
import { PanelBase } from "office-ui-fabric-react/lib/components/Panel/Panel.base";
import React from "react";
import { Route, Switch } from "react-router-dom";
import Auth from "views/login/Auth";

export interface IAppState {
  locale: string;
}

@autobind
class App extends React.Component<{}, IAppState> {
  private log: any;
  constructor(props) {
    super(props);

    this.state = {
      locale: getSchoolLocale(),
    };

    this.log = getLogger("App");
    this.log.info("Initiating app...");
    this.log.info("Default locale *" + this.state.locale + "*"); // markup in logging example
    i18nMsg.setLanguage(this.state.locale);

    window.school_locale = this.state.locale;
  }
  // to change locale
  // private changeLocale(locale: string = "mr"){
  //   this.log.info(`Changing locale to ${locale}`);
  //   i18nMsg.setLanguage(locale);
  //   this.setState({locale});
  // }

  public componentDidMount() {
    // Fix for Panel OuterClick issue (see https://github.com/OfficeDev/office-ui-fabric-react/issues/8470)
    // @ts-ignore
    PanelBase.prototype._shouldListenForOuterClick = (props: IPanelProps) =>
      !props.isBlocking && !!props.isOpen;
  }
  public render() {
    return (
      <div className="container-fluid">
        <Switch>
          <Route path={AppURLs.AUTH_URL} component={Auth} />
          <Route path={AppURLs.ROOT_PATH} component={Main} />
        </Switch>
      </div>
    );
  }
}

export default enableI18n(App);
