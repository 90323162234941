/*eslint-disable*/
import autobind from "autobind-decorator";
import * as React from "react";

// const NORMALIZED_KEYS = {
//   Esc: "Escape",
//   Spacebar: " ",
//   Left: "ArrowLeft",
//   Up: "ArrowUp",
//   Right: "ArrowRight",
//   Down: "ArrowDown",
//   Del: "Delete",
//   Win: "OS",
//   Menu: "ContextMenu",
//   Apps: "ContextMenu",
//   Scroll: "ScrollLock",
//   MozPrintableKey: "Unidentified",
// };

// const KEY_CODE_KEYS = {
//   8: "Backspace",
//   9: "Tab",
//   12: "Clear",
//   13: "Enter",
//   16: "Shift",
//   17: "Control",
//   18: "Alt",
//   19: "Pause",
//   20: "CapsLock",
//   27: "Escape",
//   32: " ",
//   33: "PageUp",
//   34: "PageDown",
//   35: "End",
//   36: "Home",
//   37: "ArrowLeft",
//   38: "ArrowUp",
//   39: "ArrowRight",
//   40: "ArrowDown",
//   45: "Insert",
//   46: "Delete",
//   112: "F1",
//   113: "F2",
//   114: "F3",
//   115: "F4",
//   116: "F5",
//   117: "F6",
//   118: "F7",
//   119: "F8",
//   120: "F9",
//   121: "F10",
//   122: "F11",
//   123: "F12",
//   144: "NumLock",
//   145: "ScrollLock",
//   224: "Meta",
// };

export interface IKeyMapping {
  key: string;
  fun: (event?) => void;
}

export interface IGlobalKeyHandlerProps {
  mappings: IKeyMapping[];
}
/*
 * Double global key Handler inspired by
 * https://github.com/ayrton/react-key-handler
 *
 * Usage: this requires mapping of key and function
 *  eg mappings = {
 *      'Alt+n':function(),
 *     }
 * @class GlobalKeyHandler
 * @extends {React.PureComponent}
 */
@autobind
class GlobalKeyHandler extends React.PureComponent<IGlobalKeyHandlerProps, {}> {
  constructor(props: IGlobalKeyHandlerProps) {
    super(props);
  }

  public componentDidMount() {
    window.document.addEventListener("keydown", this.handleDownKey);
  }

  public componentWillUnmount() {
    window.document.removeEventListener("keydown", this.handleDownKey);
  }

  public isInput(element: HTMLElement) {
    if (!element) {
      return false;
    }

    const { tagName } = element;
    const editable = this.isContentEditable(element);

    if (tagName === "INPUT" && element.type === "checkbox"){
        return false;
    }
    return tagName === "INPUT" || tagName === "TEXTAREA" || editable;
  }

  public isContentEditable(element: any) {
    if (typeof element.getAttribute !== "function") {
      return false;
    }

    return !!element.getAttribute("contenteditable");
  }

  public getFun(key: string) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.props.mappings.length; i++) {
      if (this.props.mappings[i].key === key) {
        return this.props.mappings[i].fun;
      }
    }
  }
  public handleDownKey(event: any) {
    const { target } = event;
    if (event.altKey && event.keyCode !== 18) {
      const index: string = "Alt+" + event.key;
      const fun = this.getFun(index);
      const getType = {};
      if (fun && getType.toString.call(fun) === "[object Function]") {
        event.preventDefault();
        fun(event);
      }
    } else {
      // control based combination like ctrl enter
      if (event.ctrlKey && event.keyCode !== 17) {
        const index: string = "Ctrl+" + event.key;
        const fun = this.getFun(index);
        const getType = {};
        if (fun && getType.toString.call(fun) === "[object Function]") {
          event.preventDefault();
          fun(event);
        }
      } else {
        // non alt ctrl based combination. e.g. delete must be protected from getting captured from input boxes

        // do not catch this if target html element such as a editor box
        if (target instanceof HTMLElement && this.isInput(target)) {
          return;
        }

        const index: string = event.key;
        const fun = this.getFun(index);
        const getType = {};
        if (fun && getType.toString.call(fun) === "[object Function]") {
          event.preventDefault();
          fun(event);
        }
      }
    }
  }

  public render() {
    return null;
  }
}

export default GlobalKeyHandler;
