import autobind from "autobind-decorator";
import { DataStrategy } from "../../DataStrategy";
import { IApiCaller, ICommonRequestConfig } from "../WebApiCaller/APICaller";
import ApiCallerImpl from "../WebApiCaller/ApiCallerImpl";
import { IDao } from "./IDao";

export interface ICachedDataMap {
  [key: string]: ICachedDataContents;
}

export interface ICachedDataContents {
  list: any[];
  map: any;
}

/**
 * Data Holder class responsible for serving data
 * It will return data from cache if cache is populated
 * otherwise it will fetch data from server
 */
@autobind
class BaseDaoImpl implements IDao {
  /**
   * cachedDataMap will hold url:data mapping,
   * It will be populated in the class which extends BaseDaoImpl
   */

  public dataMap: ICachedDataMap = {};

  /**
   * Make api calls to backed server
   */
  private apiCaller: IApiCaller;

  constructor() {
    this.apiCaller = new ApiCallerImpl();
  }

  /**
   * Common function which is responsible for deciding whether to return data from
   * cache or fetch from backed
   *
   * @param request
   */
  public doRequest(request: ICommonRequestConfig) {
    // check cached data or make api call to get it

    if (request.dataStrategy === DataStrategy.CACHE_DATA) {
      if (this.dataMap[request.url!] === undefined) {
        // no cache is populated for this url
        return this.performActionOnServer(request);
      } else {
        // return from cache
        request.successCallback!(this.dataMap[request.url!], request);
      }
    } else {
      return this.performActionOnServer(request);
    }
  }

  /**
   * Method to do provided action on server
   *
   * @param request
   */
  private performActionOnServer(request: ICommonRequestConfig) {
    return this.apiCaller.makeAPICall(request);
  }

  /**
   * Method to refresh data
   * @param request
   */
  public refreshData(request: ICommonRequestConfig) {
    this.performActionOnServer(request);
  }
}

export default BaseDaoImpl;
