import { i18nMsg } from "common_assets/i18n/i18n";
import { getLogger } from "common_assets/LoggerUtil/LoggerUtil";
import * as React from "react";

const getDisplayName = (WrappedComponent, defaultName = "Unknown") => {
  return WrappedComponent.displayName || WrappedComponent.name || defaultName;
};

const enableI18n = (Child) => {
  class EnableI18n extends React.PureComponent {
    private log: any;
    constructor(props) {
      super(props);

      this.log = getLogger("EnableI18n");
      this.log.info(`Init ${EnableI18n.displayName} for localization`);
    }

    public static displayName = `EnableI18n(${getDisplayName(
      Child,
      "EnableI18nChild",
    )})`;

    public render() {
      return <Child {...this.props} i18nMsg={i18nMsg} />;
    }
  }
  return EnableI18n;
};

export default enableI18n;
