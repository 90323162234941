/*
 * Copyright (C) 2017-present Connection Loops Pvt. Ltd., Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/
import { AppURLs } from "common_assets/URL";
import enableI18n from "decorators/EnableI18n";
import React from "react";
import { Route } from "react-router";
import LoginForm from "./LoginForm";
import style from "./style.scss";

const Auth: React.StatelessComponent<{}> = (props) => {
  const containerStyle = {
    backgroundImage: `url(/Images/login/sun.jpeg)`,
  };

  const logopath = "/Images/logo_lg.png";

  return (
    <div style={containerStyle} className={style.login_container}>
      <div className="ms-Grid">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-lg8 hiddenSm">
            <span />
          </div>
          <div className="ms-Grid-col ms-sm12 ms-lg4">
            <div className={style.login_form_container}>
              <header className={style.login_header + " ms-fadeIn500"}>
                <img className={style.login_logo} src={logopath} />
                {/* <p className={style.login_cp_name}>CL School</p> */}
              </header>

              <div>
                {/* login and registration components will mount here */}
                <Route path={AppURLs.LOGIN_URL} component={LoginForm}/>
              </div>
              <div className={style.common_footer}>
                {/* &copy; Cloud Pathology {new Date().getFullYear()} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default enableI18n(Auth);
