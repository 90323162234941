exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "body{font-size:1rem;margin:0;padding:0}ul,ol{list-style:none;padding:0}a{text-decoration:none;color:#0078d7}._2tQW{color:#d50000 !important}.FUp9{display:flex;flex-direction:row;height:95vh;width:100%;overflow:hidden}.eSyq{overflow-y:auto;overflow-x:hidden;padding:0 10px}._21iQ{position:relative;z-index:1}._21iQ::before{content:\"\";position:absolute;z-index:-1;top:0;bottom:0;left:-0.25em;right:-0.25em;background-color:rgba(252,49,113,.75);transform-origin:center right;transform:scaleX(0);transition:transform .2s ease-in-out}._21iQ:hover::before{transform:scaleX(1);transform-origin:center left}", ""]);

// Exports
exports.locals = {
	"active_link_class": "_2tQW",
	"main_container": "FUp9",
	"main_content": "eSyq",
	"highlight": "_21iQ"
};