/*
 * Copyright (C) 2017-present Connection Loops Pvt. Ltd., Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
*/
/*
* Author : Ashutosh Mangalekar
* Date : 2017-06-14 23:32:34
* Description:
* This file contains the Constants
*/
export const logLevel = {
  DEBUG: 2,
  INFO: 1,
  ERROR: 0,
};

export const enum notificationType {
  ERROR   = 0,
  SUCCESS = 1,
  WARNING = 2,
  INFO    = 3,
}

export const enum TextRenderingType {
  UPPER_CASE   = 0,
  LOWER_CASE = 1,
  SENTENCE_CASE = 2,
  CAMEL_CASE = 3,
  NO_OP = -1,
}

export interface ICPModule {
  id: string;
  name?: string;
  description?: string;
  permission?: number;
}

export interface ICPPermission {
  uuid?: string;
  moduleID: string;
  permission: number;
  CRUD?: boolean[];
  op?: string;
  [key: string]: any;
}

/*
c 1000 = 8
r 0100 = 4
u 0010 = 2
d 0001 = 1
*/
export const enum CPPermissions {
  CREATE = 8,
  READ   = 4,
  UPDATE = 2,
  DELETE = 1,
}

export const CPPermissionOp = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
};

export const enum FormattingOption {
  BOLD = 1,
  INCLUDE_NORMAL_VALUE = 2,
}

export const preferenceConstants  = {
  TABLE_COLUMN_ORDER_PREF: "table_column_order",
  TABLE_COLUMN_HIDDEN_PREF: "table_column_hidden",
  TABLE_COLUMN_WIDTH_PREF: "table_column_width",
  RCPT_NO_PREF: "pgBrkNoOfRcpt",
  DEF_PRINT_RCPT_PREF: "defRcptPrint",
};
