import LocalizedStrings from "react-localization";

/**
 * This file contains all localized string
 * All Language codes reference https://www.science.co.il/language/Codes.php
 * seperate key with _
 */
export const i18nMsg = new LocalizedStrings({
  en: {
    // default english
    // start login strings
    ISO_Num: "ISO Number",
    rc_nrr: "Narration",
    leaving_cert_srno: "LC. No.",
    father_father_name: "Father's Full Name",
    lc_year: "LC Year",
    receipt_yr: "Show Receipts in Year",
    login: "Log In",
    logout: "Log Out",
    enter_username: "Enter username",
    enter_password: "Enter password",
    dont_have_account: "Don't have account?",
    signup_here: "Sign up here.",
    forget_password: "Forget password?",
    invalid_credentials: "Invalid credentials.",
    // end login strings
    back_to_login: "Back to login",
    // registration
    mobile_no: "10 digit mobile no.",
    full_name: "Full name",
    email: "Email",
    password: "Password",
    repeat_password: "Repeat password",
    // student table columns
    student_name: "Student Name",
    stdnt_id: "Student Id",
    saral_no: "Saral. No",
    uid_no: "AADHAR No.",
    gen_reg_no: "General Reg. No.",
    first_name: "First Name",
    middle_name: "Middle Name",
    last_name: "Last Name",
    mother_name: "Mother Name",
    first_name_e: "First Name (English)",
    middle_name_e: "Middle Name (English)",
    last_name_e: "Last Name (English)",
    mother_name_e: "Mother Name (English)",
    school_id: "School Id",
    nationality: "Nationality",
    mother_tongue: "Mother Tongue",
    nationality_e: "Nationality (English)",
    mother_tongue_e: "Mother Tongue (English)",
    religion: "Religion",
    caste: "Caste",
    sub_caste: "Sub Caste",
    category: "Category",
    religion_e: "Religion (English)",
    caste_e: "Caste (English)",
    sub_caste_e: "Sub Caste (English)",
    category_e: "Category (English)",
    sex: "Sex",
    age: "Age",
    sex_e: "Sex (English)",
    birth_place: "Birth Place",
    birth_place_e: "Birth Place (English)",
    birth_place_city: "Birth City",
    birth_place_city_e: "Birth City (English)",
    birth_place_taluka: "Birth Taluka",
    birth_place_taluka_e: "Birth Taluka (English)",
    birth_place_district: "Birth District",
    birth_place_district_e: "Birth District (English)",
    birth_place_state: "Birth State",
    birth_place_state_e: "Birth State (English)",
    birth_place_country: "Birth Country",
    birth_place_country_e: "Birth Country (English)",
    dob: "Date of Birth",
    academic_yr: "Academic Year",
    admission_dt: "Admission Date",
    admission_std: "Admission Standard",
    current_std: "Current Standard",
    current_div: "Current Division",
    current_div_e: "Current Division (English)",
    current_roll_no: "Catalog No.",
    progress_in_studies: "Progress in Studies",
    conduct: "Conduct",
    progress_in_studies_e: "Progress in Studies (English)",
    conduct_e: "Conduct (English)",
    leaving_dt: "Leaving Date",
    issue_dt: "Issue Date",
    leaving_std: "Leaving Standard",
    leaving_std_dt: "Leaving Standard & Date: ",
    leaving_reason: "Leaving Reason",
    leaving_reason_e: "Leaving Reason (English)",
    cur_std_dt: "Current Standard Date",
    remarks: "Remarks",
    remarks_e: "Remarks (English)",
    gen_reg_book_no: "General Registration Book No.",
    effective_date: "Effective Date",
    sys_created: "Added Date",
    sys_updated: "Last Updated Date",
    snap_id: "Snapshot ID",
    last_school: "Previous School",
    last_std: "Previous Standard",
    required_field_error_msg_with_field: '"{label}" is required field.',
    required_field_error_msg: "Required field.",
    invalid_field_error_msg_with_field:
      'Please enter valid value for "{label}" ',
    invalid_field_error_msg: "Invalid field value.",
    presenty: "Current Presenty",
    cummulativePresenty: "Cummulative Presenty",
    class_teacher: "Class Teacher",
    fee: "Fee",
    due_fee: "Due Fee",
    fee_details: "Fee Status",

    a_cc: "Admission Std/Div",
    c_cc: "Current Std/Div",
    phone_no: "Phone No.",
    u_dise_no: "UDISE Code",
    registration_no: "Registration No.",
    address: "Address",
    language: "Language",
    school_name: "School Name",
    college_name: "College Name",
    place: "Place",
    medium: "medium",
    school_board: "Board",
    index_no: "Index No.",
    board_no: "Board No.",
    standard: "Standard",
    branch: "Branch",
    division: "Division",
    science: "Science",
    arts: "Arts",
    commerce: "Commerce",
    group: "Group",
    admission_yr: "Admission year",

    // standards
    std_n3: "-",
    std_n2: "Mini KG",
    std_n1: "Jr. KG",
    std_n0: "Sr. KG",
    std_1: "1st",
    std_2: "2nd",
    std_3: "3rd",
    std_4: "4th",
    std_5: "5th",
    std_6: "6th",
    std_7: "7th",
    std_8: "8th",
    std_9: "9th",
    std_10: "10th",
    std_11: "11th",
    std_12: "12th",
    std_13: "BA-I",
    std_14: "BA-II",
    std_15: "BA-III",
    std_16: "BSc.I",
    std_17: "BSc.II",
    std_18: "BSc.III",
    std_all: "ALL",

    // divisions
    div_a: "A",
    div_b: "B",
    div_c: "C",
    div_d: "D",
    div_e: "E",
    div_f: "F",
    div_g: "G",
    div_h: "H",
    div_i: "I",
    div_all: "ALL",
    div_x: "-",

    primary_language: "Primary Language",
    current_address: "Current Address",
    permanant_address: "Permanant Address",
    bank_account: "Bank account No.",
    blood_group: "Blood Group",
    student_mobile: "Student Mob. No.",
    father_mobile: "Mobile No. of father",
    mother_mobile: "Mobile No. of mother",
    father_occupation: "Occupation of father",
    mother_occupation: "Occupation of mother",
    parent_address: "Parent Address",
    parent_email: "Parent Email",
    student_email: "Student Email",
    parent_annul_income: "Parent's annual income",

    current_branch: "Current Branch",
    admission_div: "Admission Division",
    current_std_date: "Current Standard Date",
    male: "Male",
    female: "Female",
    fee_type: "Fee Type",
    title: "Title",
    description: "Description",
    amount: "Amount",
    payment_mode: "Payment Mode",
    hm_no: "Headmaster Phone No.",
    principal_no: "Principal Phone No.",
    narration: "Narration",
    payee_type: "Payee Type",
    sort_order: "SO",
    payment_dt: "Payment Date",
    ebc_ft: "EBC",
    full_pay_ft: "Full Paying",
    tc_ft: "Teacher Child",
    sc_ft: "Soldier Child",
    bcr_ft: "BCR",
    free_ft: "Free",
    rte_ft: "RTE",

    religion_caste_subcaste: "Religion & Caste",
    admission_details: "Admission Details",
    admission_type: "Admission Type",
    receipt_prefix: "Receipt Prefix",

    university: "University",
  },
  mr: {
    // marathi
    // start login strings
    ISO_Num: "आयएसओ क्रमांक",
    login: "Log In",
    logout: "Log Out",
    enter_username: "Enter username",
    enter_password: "Enter password",
    dont_have_account: "Don't have account?",
    signup_here: "Sign Up Here.",
    forget_password: "खाते विसरलात?",
    invalid_credentials: "Invalid credentials.",
    // end login strings
    mobile_no: "10 digit mobile no.",
    email: "Email",
    password: "Password",
    repeat_password: "Repeat password",

    // student table columns
    stdnt_id: "विद्यार्थी ओळखपत्र",
    saral_no: "सरल क्रमांक",
    uid_no: "आधार क्रमांक",
    gen_reg_no: "जनरल रजि. क्रं.",
    first_name: "पहिले नाव",
    middle_name: "मधले नाव",
    last_name: "आडनाव",
    mother_name: "आईचे नाव",
    nationality: "राष्ट्रीयत्व",
    mother_tongue: "मातृभाषा",
    religion: "धर्म",
    caste: "जात",
    sub_caste: "पोटजात",
    category: "संवर्ग",
    sex: "लिंग",
    birth_place: "जन्मस्थान",
    birth_place_city: "जन्म गाव/शहर",
    birth_place_taluka: "जन्म तालुका",
    birth_place_district: "जन्म जिल्हा",
    birth_place_state: "जन्म राज्य",
    birth_place_country: "जन्म देश",
    dob: "जन्म तारीख",
    age: "वय",
    academic_yr: "चालू शैक्षणिक वर्ष",
    receipt_prefix: "पावती अद्याक्षर",
    admission_dt: "प्रवेशाची तारीख",
    admission_std: " प्रवेशाची इयत्ता",
    current_std: "चालू इयत्ता",
    a_cc: "प्रवेशाची इयत्ता / तुकडी ",
    c_cc: "चालू इयत्ता / तुकडी ",
    current_div: "चालू तुकडी",
    current_roll_no: "कॅटलॉग नं.",
    progress_in_studies: "अभ्यासातील प्रगती",
    conduct: "वागणूक",
    leaving_dt: "सोडण्याचा दिनांक",
    issue_dt: "दाखला दिल्याची तारीख",
    leaving_std: "शाळा सोडताना इयत्ता",
    leaving_std_dt: "शाळा सोडताना इयत्ता व तारीख: ",
    leaving_reason: "शाळा सोडल्याचे कारण",
    cur_std_dt: "चालू इयत्तेत केंव्हा पासुन शिकत आहे",
    remarks: "शेरा",
    gen_reg_book_no: "जनरल रजि. बुक. नं.",
    effective_date: "प्रभावी तारीख",
    sys_created: "घातलेली तारीख",
    sys_updated: "तारीख सुधारित केली",
    bonafied_certificate: "बोनाफाईड सर्टिफिकेट",
    last_school: "पूर्वीची शाळा",
    last_std: "पूर्वीची इयत्ता",
    required_field_error_msg_with_field: '"{label}" ची माहिती भरणे आवश्यक आहे.',
    required_field_error_msg: "माहिती भरणे आवश्यक आहे.",
    invalid_field_error_msg_with_field:
      '"{label}" साठी भरलेली माहिती चूकीची आहे.',
    invalid_field_error_msg: "भरलेली माहिती चूकीची आहे.",
    presenty: "चालू उपस्थिती",
    cummulativePresenty: "मागील उपस्थिती",
    class_teacher: "वर्ग शिक्षक",
    fee: "फी",
    due_fee: "शिल्लक फी",
    fee_details: "फी माहिती",

    phone_no: "दूरध्वनी क्रमांक.",
    u_dise_no: "यूडीएसई कोड",
    registration_no: "नाॊंदणी क्रमांक.",
    address: "पत्ता",
    language: "भाषा",
    school_name: "शाळेचे नाव",
    college_name: "College Name",
    place: "ठिकाण",
    medium: "माध्यम",
    school_board: "बोर्ड",
    index_no: "अनुक्रमणिका क्रमांक",
    board_no: "Board No.",
    standard: "इयत्ता",
    branch: "शाखा",
    division: "तुकडी",
    science: "विज्ञान",
    arts: "कला",
    commerce: "वाणिज्य",
    group: "Group",

    // standards
    std_n3: "-",
    std_n2: "Mini KG",
    std_n1: "लहान गट",
    std_n0: "मोठा गट",
    std_1: "१ ली",
    std_2: "२ री",
    std_3: "३ री",
    std_4: "४ थी",
    std_5: "५ वी",
    std_6: "६ वी",
    std_7: "७ वी",
    std_8: "८ वी",
    std_9: "९ वी",
    std_10: "१० वी",
    std_11: "११ वी",
    std_12: "१२ वी",
    std_13: "BA-I",
    std_14: "BA-II",
    std_15: "BA-III",
    std_16: "BSc.I",
    std_17: "BSc.II",
    std_18: "BSc.III",
    std_all: "ALL",

    // divisions
    div_a: "अ",
    div_b: "ब",
    div_c: "क",
    div_d: "ड",
    div_e: "इ",
    div_f: "फ",
    div_g: "ग",
    div_h: "ह",
    div_i: "त",
    div_all: "ALL",
    div_x: "-",

    primary_language: "प्रथमिक भाषा",
    marathi: "मराठी",

    current_address: "सध्या राहत्या घराचा पत्ता",
    permanant_address: "कायमचा पत्ता",

    bank_account: "बँक खाते क्रमांक",
    blood_group: "रक्त गट",
    student_mobile: "विद्यार्थी फोन नं.",
    father_mobile: "वडिलांचा मोबाइल नंबर",
    mother_mobile: "आईचा मोबाइल नंबर",
    father_occupation: "वडिलांचा व्यवसाय",
    mother_occupation: "आईचा व्यवसाय",
    parent_address: "पालकांचा पत्ता",
    parent_annul_income: "पालकांचे वार्षिक उत्पन्न",
    parent_email: "Parent Email",
    student_email: "Student Email",
    admission_branch: "प्रवेशाची शाखा",
    admission_div: "प्रवेशाची तुकडी",
    current_branch: "प्रवेशाची शाखा",
    male: "पुरुष",
    female: "स्त्री",

    current_std_date: "चालू इयत्ता तारीख",
    fee_type: "शुल्क प्रकार",
    title: "Title",
    description: "Description",
    amount: "Amount",
    payment_mode: "Payment Mode",
    narration: "Narration",
    payee_type: "Payee Type",
    sort_order: "SO",

    ebc_ft: "ई.बी.सी.",
    full_pay_ft: "पूर्ण फी",
    tc_ft: "शिक्षक पाल्य",
    sc_ft: "सैनिक पाल्य",
    bcr_ft: "BCR",
    free_ft: "विनामुल्य",
    rte_ft: "RTE",

    full_name: "पूर्ण नाव",
    religion_caste_subcaste: "धर्म व जात",
    admission_details: "प्रवेशाची माहीती",
    admission_yr: "प्रवेशाचे साल",
    hm_no: "Headmaster Phone No.",
    principal_no: "Principal Phone No.",
    payment_dt: "Payment Date",
    receipt_yr: "Show Receipts in Year",
    admission_type: "प्रवेशाचा प्रकार",
    lc_year: "दाखल्याचे वर्ष",

    university: "University",

    father_father_name: "वडीलांचे पूर्ण नाव",
    student_name: "विद्यार्थ्याचे नाव",
    leaving_cert_srno: "LC. No.",
    rc_nrr: "Narration",
  },
  hi: {
    // hindi
  },
  kn: {
    // Kannada
  },
});
