import React, { ComponentType } from "react";

interface IErrorBoundaryState {
  hasError: boolean;
  info: any;
  error: any;
}

export default class ErrorBoundary extends React.Component<{},
  IErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      info: "",
      error: "",
    };
  }

  public static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: any, info: any) {
    // You can also log the error to an error reporting service
    // TODO; log to backend
    this.setState({ hasError: true, error, info });
    // tslint:disable-next-line:no-console
    console.group("omponentDidCatch");
    console.error(error);
    console.info(info);
    console.groupEnd();
  }

  public render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/Images/oops.png" />

          {process.env.NODE_ENV === "development" && (
            <details style={{ whiteSpace: "pre-wrap" }}>
              What:
              <span style={{ color: "#d50000" }}>
                {this.state.error.toString()}
              </span>
              <br />
              Where:
              {this.state.info.componentStack}
            </details>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export const withErrorBoundary = (
  Component: ComponentType<any>,
): any => {
  const Wrapped = (props) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );

  // Format for display in DevTools
  const name = Component.displayName || Component.name;
  Wrapped.displayName = name
    ? `WithErrorBoundary(${name})`
    : "WithErrorBoundary";

  return Wrapped;
};
