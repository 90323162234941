import autobind from "autobind-decorator";
import {
  getSchoolInfo,
  getUserObject,
  IUserStoredObj,
} from "common_assets/CLStorage/CLStorage";
import { AppURLs } from "common_assets/URL";
import { withErrorBoundary } from "components/BasicComponents/ErrorBoundary/ErrorBoundary";
import MenuPanel from "components/MenuPanel";
import NavBar from "components/NavBar";
import React, { PureComponent } from "react";
import Loadable from "react-loadable";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "./app.scss";

const MainWithLodable = Loadable({
  loader: () => import("MainRouter"),
  loading() {
    return <div>Loading...</div>;
  },
});

export interface IMainState {
  isMenuOpen: boolean;
}

const SchoolSettingContext = React.createContext({ locale: "en" });

@withErrorBoundary
@autobind
class Main extends PureComponent<{}, IMainState> {
  private menuWidth: number;
  private schoolName = "";
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: true,
    };

    this.menuWidth = 10;
  }

  public componentWillMount() {
    const userInfo = getUserObject() as IUserStoredObj;
    const schoolInfo = getSchoolInfo();

    if (!userInfo || !schoolInfo) {
      window.location.href = "#" + AppURLs.LOGIN_URL;
      return;
    }
    this.schoolName = schoolInfo!.sanstha_name;
  }
  private handleMenuToggle() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }
  public render() {
    const menuWidth = this.state.isMenuOpen ? this.menuWidth : 0;
    const menuMinWidth = this.state.isMenuOpen ? 180 : 0;
    const containerWidth = this.state.isMenuOpen ? 100 - this.menuWidth : 100;

    const leftNavAnimationClassName = this.state.isMenuOpen
      ? "ms-slideRightIn40"
      : "ms-slideLeftOut40";
    return (
      <SchoolSettingContext.Provider value={{ locale: "en" }}>
        <div>
          <ToastContainer
            newestOnTop={true}
            closeOnClick={true}
            style={{ zIndex: 100000000000 }}
          />
          <NavBar
            onMenuToggle={this.handleMenuToggle}
            pagename={this.schoolName}
            bottomShadow={true}
          />
          <div className={style.main_container}>
            <div
              className={leftNavAnimationClassName + " " + style.left_navbar}
              style={{
                width: menuWidth + "%",
                minWidth: menuMinWidth,
                borderRight: "1px solid gainsboro",
                boxShadow: "0px 0px 10px rgba(128, 128, 128, 0.3)",
              }}
            >
              <MenuPanel />
            </div>
            <div
              className={style.main_content}
              style={{ width: containerWidth + "%" }}
            >
              <MainWithLodable {...this.props} />
            </div>
          </div>
        </div>
      </SchoolSettingContext.Provider>
    );
  }
}

export default Main;
