exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "body{font-size:1rem;margin:0;padding:0}ul,ol{list-style:none;padding:0}a{text-decoration:none;color:#0078d7}._2a-x{color:#d50000 !important}.i-rM{display:flex;flex-direction:row;height:95vh;width:100%;overflow:hidden}._3Xme{overflow-y:auto;overflow-x:hidden;padding:0 10px}._2oJw{position:relative;z-index:1}._2oJw::before{content:\"\";position:absolute;z-index:-1;top:0;bottom:0;left:-0.25em;right:-0.25em;background-color:rgba(252,49,113,.75);transform-origin:center right;transform:scaleX(0);transition:transform .2s ease-in-out}._2oJw:hover::before{transform:scaleX(1);transform-origin:center left}._2qDN{height:7vh;display:flex;user-select:none}@media screen and (max-width: 1366px){._2qDN{height:8vh !important}}._22kC{box-shadow:0 0 10px rgba(128,128,128,.25);margin-bottom:5px}._15zK{display:flex;flex-direction:row;padding:0 30px;align-items:center}.S9Gb{width:40px;height:40px}._2RnD{margin:auto;padding-left:10px;font-size:21px;font-weight:400}._2RnD>a{color:#000}._2XSx{margin-left:16px;cursor:pointer}._2XSx:hover{color:#03a9f4}.ejew{display:flex;align-items:center}._2oaV{position:absolute;right:30px;display:flex;align-items:center;height:inherit}.c-RA{margin-right:16px}._20lT{color:#e81123}._17OH{color:#e81123;animation:iwKx 1s steps(5, start) infinite;-webkit-animation:iwKx 1s steps(5, start) infinite;height:6vh;display:flex;user-select:none}@keyframes iwKx{to{visibility:hidden}}._1y_b{width:200px;box-shadow:0 0 10px -5px rgba(0,0,0,.4);padding:16px;user-select:none}p{margin:5px}.n1EK{margin-top:25px}.slHp{margin:16px}.HaSd{font-size:larger;vertical-align:middle;margin:0px 10px 0px 0px}._1Evb{color:gray;text-decoration:none;vertical-align:middle}._1Evb:focus{outline:none;color:gray}._2P63{display:flex}._3UQu{font-size:1.3rem;color:gray;margin:auto;cursor:default}._3zcM{align-items:center;display:flex;border-right:1px solid rgba(191,191,191,.467)}", ""]);

// Exports
exports.locals = {
	"active_link_class": "_2a-x",
	"main_container": "i-rM",
	"main_content": "_3Xme",
	"highlight": "_2oJw",
	"navbar": "_2qDN",
	"bottomshadow": "_22kC",
	"labHeader": "_15zK",
	"lablogo": "S9Gb",
	"labname": "_2RnD",
	"globalMenuIcon": "_2XSx",
	"LeftGlobalMenuIcon": "ejew",
	"rightMenu": "_2oaV",
	"rightMenuIcons": "c-RA",
	"red": "_20lT",
	"blink": "_17OH",
	"blink-animation": "iwKx",
	"userCallOut": "_1y_b",
	"iconMenu": "n1EK",
	"iconMenuItem": "slHp",
	"iconMenuItemIcon": "HaSd",
	"iconMenuItemText": "_1Evb",
	"pageNameContainer": "_2P63",
	"pageName": "_3UQu",
	"pauseSystemContainer": "_3zcM"
};