import { Column, TableColumnWidthInfo } from "@devexpress/dx-react-grid";
import CLStorage from "common_assets/CLStorage";
import { ISchoolClass } from "common_assets/DataRepository/dao/SchoolDao";
import { i18nMsg } from "common_assets/i18n/i18n";
import { getStdAbsVal } from "common_assets/Util";
import { OptionType } from "components/BasicComponents/CLSelect/CLSelect";

export interface ISchoolSettings {
  school_name: string;
  school_name_e: string;
  place: string;
  place_e: string;
  medium: string;
  medium_e: string;
  board: string;
  board_e: string;

  reg_no: string;
  url: string;
  index_no: string;
  u_dise_no: string;
  phone_no: string;
  email: string;
  addr: string;
  hm_no: string;

  language_locale: string;

  ISO_Num: string; // added the iso num field
}

export default class SchoolInfo {
  public static getAllClassColumns(): Column[] {
    return [
      {
        name: "std",
        title: i18nMsg.standard,
      },
      {
        name: "div",
        title: i18nMsg.division,
      },
      {
        name: "branch",
        title: i18nMsg.branch,
      },
    ];
  }

  public static getClassColumnsWithWidths(prefKey): TableColumnWidthInfo[] {
    // read preference storagte for getting changed hidden columns
    const columnWidths = new CLStorage().getTableColumnWidthPreference(prefKey);
    if (columnWidths === null || columnWidths === undefined) {
      const columnWithWidth: TableColumnWidthInfo[] = [];
      SchoolInfo.getAllClassColumns().forEach((column) => {
        columnWithWidth.push({
          columnName: column.name,
          width: 180,
        });
      });

      return columnWithWidth;
    } else {
      return columnWidths;
    }
  }

  public static getDivisionMap(locale: string): { [key: string]: string } {
    return {
      a: i18nMsg.getString("div_a", locale),
      b: i18nMsg.getString("div_b", locale),
      c: i18nMsg.getString("div_c", locale),
      d: i18nMsg.getString("div_d", locale),
      e: i18nMsg.getString("div_e", locale),
      f: i18nMsg.getString("div_f", locale),
      g: i18nMsg.getString("div_g", locale),
      h: i18nMsg.getString("div_h", locale),
      i: i18nMsg.getString("div_i", locale),
      x: i18nMsg.getString("div_x", locale),
      all: i18nMsg.getString("div_all", locale),
    };
  }

  public static getAllDivisions(locale: string): OptionType[] {
    const list: OptionType[] = [];
    const divMap = SchoolInfo.getDivisionMap(locale);

    for (const divKey of Object.keys(divMap)) {
      list.push({
        value: divKey,
        label: divMap[divKey],
      });
    }

    return list;
  }

  public static getStandardMap(locale: string): { [key: string]: string } {
    return {
      n3: i18nMsg.getString("std_n3", locale),
      n2: i18nMsg.getString("std_n2", locale),
      n1: i18nMsg.getString("std_n1", locale),
      n0: i18nMsg.getString("std_n0", locale),
      1: i18nMsg.getString("std_1", locale),
      2: i18nMsg.getString("std_2", locale),
      3: i18nMsg.getString("std_3", locale),
      4: i18nMsg.getString("std_4", locale),
      5: i18nMsg.getString("std_5", locale),
      6: i18nMsg.getString("std_6", locale),
      7: i18nMsg.getString("std_7", locale),
      8: i18nMsg.getString("std_8", locale),
      9: i18nMsg.getString("std_9", locale),
      10: i18nMsg.getString("std_10", locale),
      11: i18nMsg.getString("std_11", locale),
      12: i18nMsg.getString("std_12", locale),
      13: i18nMsg.getString("std_13", locale),
      14: i18nMsg.getString("std_14", locale),
      15: i18nMsg.getString("std_15", locale),
      // BSC
      16: i18nMsg.getString("std_16", locale),
      17: i18nMsg.getString("std_17", locale),
      18: i18nMsg.getString("std_18", locale),

      all: i18nMsg.getString("div_all", locale),
    };
  }
  public static getStdAbsVal(std: string) {
    if (std === "n3") {
      return -3;
    }
    if (std === "n2") {
      return -2;
    }
    if (std === "n1") {
      return -1;
    }
    if (std === "n0") {
      return 0;
    }
    return parseInt(std, 10);
  }
  private static stdSoFn(f: string, s: string) {
    return (getStdAbsVal(f) - getStdAbsVal(s));
  }

  public static getAllStd(locale: string): OptionType[] {
    const list: OptionType[] = [];
    const stdMap = SchoolInfo.getStandardMap(locale);
    const stdList = Object.keys(stdMap).sort(this.stdSoFn);
    for (const stdKey of stdList) {
      list.push({
        value: stdKey,
        label: stdMap[stdKey],
      });
    }

    return list;
  }

  /**
   * Funtion to return map of branch and title based on locale
   *
   */
  public static getBranchMap(locale: string): { [key: string]: string } {
    // eg.

    return {
      def: "",
      sci: i18nMsg.getString("science", locale),
      arts: i18nMsg.getString("arts", locale),
      com: i18nMsg.getString("commerce", locale),
      all: i18nMsg.getString("div_all", locale),
    };
  }

  public static getAllBranches(locale): OptionType[] {
    const list: OptionType[] = [];
    const branchMap = SchoolInfo.getBranchMap(locale);
    for (const branchKey of Object.keys(branchMap)) {
      list.push({
        value: branchKey,
        label: branchMap[branchKey],
      });
    }

    return list;
  }

  public static getExpandedClassList(data: ISchoolClass[]) {
    const stdMap = this.getStandardMap(window.school_locale);
    const divMap = this.getDivisionMap(window.school_locale);
    const branchMap = this.getBranchMap(window.school_locale);

    const classList: OptionType[] = [];
    const masterDataList: any[] = [];

    const stdBranchList: OptionType[] = [];
    const addedMap = {};
    for (const clpair of data) {
      const partsData = clpair.class_code.split("_");

      if (partsData[0] === "all") {
        continue;
      }
      classList.push({
        label:
          `${stdMap[partsData[0]]} ${divMap[partsData[2]]} ` + (partsData[1] !== "def" ? branchMap[partsData[1]] : ""),
        value: clpair.class_code,
      });

      // 5_def_a

      masterDataList.push({
        key: clpair.class_code,
        std: stdMap[partsData[0]],
        branch: branchMap[partsData[1]],
        div: divMap[partsData[2]],
      });

      // 5_def
      // 11_Sci
      const stdB = `${partsData[0]}_${partsData[1]}`; // do not add duplicate
      if (addedMap[stdB] === undefined) {
        stdBranchList.push({
          label: `${stdMap[partsData[0]]} ` + (partsData[1] !== "def" ? branchMap[partsData[1]] : ""),
          value: `${partsData[0]}_${partsData[1]}`,
        });

        addedMap[stdB] = true;
      }
    }

    return {
      classList,
      masterDataList,
      stdBranchList,
    };
  }
}
